import { registerApplication, start } from "single-spa";
import appProps from "./app-props";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import {
  getApplicationsRouteProps,
  getApplicationsServerSideProps,
  getSingleSpaLayoutElement,
} from "./util";

const singleSpaLayoutElement = getSingleSpaLayoutElement();
const applicationPropsFromRoutes = getApplicationsRouteProps(singleSpaLayoutElement);
const applicationPropsFromServerSide = getApplicationsServerSideProps();

const routes = constructRoutes(singleSpaLayoutElement);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });
applications.forEach((application) => {
  registerApplication({
    ...application,
    activeWhen:
      application.name === "@telia/b2b-dashboard-vue3"
        ? (location) => {
            return /^\/foretag\/mybusiness\/\d+\/?$/.test(location.pathname);
          }
        : application.activeWhen,
    customProps: (name: string) => ({
      ...(appProps[name as keyof typeof appProps] || {}),
      ...applicationPropsFromRoutes[name],
      ...applicationPropsFromServerSide[name],
    }),
  });
});

layoutEngine.activate();

start();
