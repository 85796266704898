interface ApplicationElementProps {
  [key: string]: Record<string, string>;
}

export const getApplicationsRouteProps = (
  singleSpaLayoutElement: HTMLTemplateElement
): ApplicationElementProps => {
  const excludedAttributes = ["name", "loader", "props"];

  return Array.from(singleSpaLayoutElement.content.querySelectorAll("application")).reduce(
    (coll: ApplicationElementProps, applicationElement) => {
      const name = "" + applicationElement.getAttributeNS(null, "name");
      coll[name] = {};

      Array.from(applicationElement.attributes)
        .filter((item) => !excludedAttributes.includes(item.name))
        .forEach((item) => {
          coll[name][item.name] = item.value;
        });

      return coll;
    },
    {}
  );
};

export const getApplicationsServerSideProps = (): ApplicationElementProps => {
  return JSON.parse(document.getElementById("__SSPA_DATA__")?.innerHTML || "{}");
};

export const getSingleSpaLayoutElement = (): HTMLTemplateElement =>
  document.getElementById("single-spa-layout") as HTMLTemplateElement;
